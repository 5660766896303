'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var chunkDWGXYN6A_cjs = require('./chunk-DWGXYN6A.cjs');
require('./chunk-57AVKP4H.cjs');

var o=chunkDWGXYN6A_cjs.a;

Object.defineProperty(exports, 'useWhisper', {
	enumerable: true,
	get: function () { return chunkDWGXYN6A_cjs.a; }
});
exports.default = o;
